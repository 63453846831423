<template>
  <b-card>
    <div class="p-1">
      <div v-if="!showSearch">
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-success"
          class="btn-icon rounded-circle"
          @click="showSearch = true"
          title="Filtros"
          v-b-tooltip.hover.v-success
        >
          <feather-icon icon="SlidersIcon" />
        </b-button>
      </div>
      <div v-else>
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="flat-danger"
          class="btn-icon rounded-circle"
          @click="showSearch = false"
          title="Fechar"
          v-b-tooltip.hover.v-danger
        >
          <feather-icon icon="ChevronUpIcon" />
        </b-button>
      </div>
      <b-row v-show="showSearch">
        <b-col md="6" xl="2" class="p-1">
          <label>Tipo de contrato</label>
          <b-form-select v-model="contractType" :options="contractTypeOption" />
        </b-col>
        <b-col md="6" xl="2" class="p-1">
          <label>Forma de cobrança</label>
          <b-form-select v-model="typeChannel" :options="typeChannelOption" />
        </b-col>
        <b-col md="6" xl="2" class="p-1">
          <label>Situação da cobrança</label>
          <b-form-select v-model="chargeSituation" :options="chargeSituationOption" />
        </b-col>
        <b-col md="6" xl="2" class="p-1">
          <label>Situação do título</label>
          <b-form-select v-model="titleSituation" :options="titleSituationOption" />
        </b-col>
        <b-col md="6" xl="2" class="p-1">
          <label>Data de início</label>
          <b-form-datepicker
            id="datepicker-dateformat1"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }"
            type="search"
            v-model="date_start"
            placeholder="Início"
            local="br"
          />
        </b-col>
        <b-col md="6" xl="2" class="p-1">
          <label>Data final</label>
          <b-form-datepicker
            id="datepicker-dateformat2"
            :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }"
            type="search"
            v-model="date_end"
            placeholder="Fim"
            local="br"
          />
        </b-col>
      </b-row>
    </div>

    <hr />
    <div>
      <b-row>
        <b-col>
          <b-button
            variant="relief-success"
            class="p-1"
            v-b-modal.modal-action-confirm
          >
            <feather-icon icon="FileTextIcon" class="mr-75" />
            Exportar relatório
          </b-button>
          <action-confirm />
        </b-col>
        <b-col md="6" xl="4" class="p-1">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input 
              placeholder="Pesquisar"
              v-model="search"
              debounce="800"
              type="search"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
        <b-table-simple
          striped
          stacked="md"
          noDataText="Você ainda não possui clientes cadastrados."
          class="mt-2 responsive"
          id="tableHistorics"
          :item="fetchHistorics"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <b-thead class="text-center">
            <b-th>Código</b-th>
            <b-th>Nome</b-th>
            <b-th>Título</b-th>
            <b-th>Situação título</b-th>
            <b-th>Canal</b-th>
            <b-th>Destinatário</b-th>
            <b-th>Situação cobrança</b-th>
            <b-th>Data e hora</b-th>
            <b-th>Colaborador</b-th>
            <b-th>Empresa</b-th>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in historics">
              <b-td> {{ tr.id }} </b-td>
              <b-td> {{ tr.client.name }} </b-td>
              <b-td> {{ tr.title_id }} </b-td>
              <b-td class="text-center">
                <b-badge v-if=" tr.title.paid == 'A'" variant="warning" class="badge-glow">
                  Em Aberto
                </b-badge>
                <b-badge v-else variant="success" class="badge-glow">
                  Pago
                </b-badge>
              </b-td>
              <b-td> {{ tr.channel.name }} </b-td>
              <b-td> {{ tr.recipient }} </b-td>
              <b-td>
                {{ tr.charge_status }}
                <!-- <b-badge variant="danger" class="badge-glow"> Falha </b-badge> -->
              </b-td>
              <b-td> {{ tr.created_at | dateFormat }} </b-td>
              <b-td> {{ tr.user.name }} </b-td>
              <b-td> {{ tr.client.tenant.company_name}} </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
      <b-pagination
        @change="handlePageChange"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        aria-controls="tableHistorics"
        class="mt-1"
      />
    </div>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { mapState } from "vuex";
import { BButton, BModal, VBModal } from "bootstrap-vue";
import ActionConfirm from "@/components/negotiation/ActionConfirm";

export default {
  components: {
    ActionConfirm,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      date_start: null,
      date_end: null,

      showSearch: false,

      search: null,

      isLoading: true,

      contractType: 'T',
      contractTypeOption: [
        { value: 'T', text: "Todos" },
        { value: "f", text: "Pessoa física" },
        { value: "j", text: "Pessoa jurídica" },
      ],

      typeChannel: 'T',
      typeChannelOption: [
        { value: 'T', text: "Todos" },
        { value: "4", text: "WhatsApp" },
        { value: "5", text: "Ligação" },
        { value: "3", text: "SMS" },
        { value: "1", text: "Email" },
        { value: "2", text: "Carta" },
        { value: "6", text: "Serasa" },
      ],

      chargeSituation: 'T',
      chargeSituationOption: [
        { value: 'T', text: "Todos" },
        { value: "Concluido", text: "Concluido" },
        { value: "Pendente", text: "Pendente" },
        { value: "Falha", text: "Falha" }
      ],

      titleSituation: 'T',
      titleSituationOption: [
        { value: 'T', text: "Todos" },
        { value: "P", text: "Pago" },
        { value: "A", text: "Em aberto" }
      ],
      
    };
  },
  computed: mapState("historics", ["historics", "pagination"]),
  methods: {
    fetchHistorics(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("historics/fetchHistorics", {
          paginated: paginated,
          page: page,
          generic: this.search,
          contract_type: this.contractType,
          channel: this.typeChannel,
          chargeSituation: this.chargeSituation,
          titleSituation: this.titleSituation,
          date_start: this.date_start,
          date_end: this.date_end,
          perPage: this.perPage,
        })
        
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePageChange(value) {
      this.fetchHistorics(true, value);
    },
  },
  created() {
    this.fetchHistorics();
  },
  watch: {
    search(val) {
      this.currentPage = 1;
      this.fetchHistorics(true, 1);
    },
    contractType(val) {
      this.currentPage = 1;
      this.fetchHistorics(true, 1);
    },
    typeChannel(val) {
      this.currentPage = 1;
      this.fetchHistorics(true, 1);
    },
    chargeSituation(val) {
      this.currentPage = 1;
      this.fetchHistorics(true, 1);
    },
    titleSituation(val) {
      this.currentPage = 1;
      this.fetchHistorics(true, 1);
    },
    date_start(val) {
      this.currentPage = 1;
      this.fetchHistorics(true, 1);
    },
    date_end(val) {
      this.currentPage = 1;
      this.fetchHistorics(true, 1);
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
