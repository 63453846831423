<!-- Modal de confirmação da exportação do relatorio -->
<template>
  <b-modal
    id="modal-action-confirm"
    ok-only
    ok-title="Exportar"
    centered
    size="lg"
    title="Exportar relatório"
  >
    <b-card-text>
      <b-alert variant="danger" show>
        <h4 class="alert-heading">Atenção!</h4>
      </b-alert>
      <b-form-checkbox v-model="selectedModal" value="gerar">
        Quero exportar os relatórios
      </b-form-checkbox>
    </b-card-text>
  </b-modal>
</template>
<script>
import { BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  data() {
    return{
      selectedModal: [],
    }
  },
  components: {
    BModal,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>